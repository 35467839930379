.heading {
    font-family: "Gilroy";
    font-weight: 600;
    font-size: 35px;
    text-align: center;
    margin-bottom: 30px;
    color: #101338;
  } 
  .perks-benefits h3 {
    font-family: "Gilroy";
    font-weight: 600;
    font-size: 35px;
    text-align: center;
    margin-bottom: 30px;
    color: #101338;
  }
  .perks-benefits h3.feature-head {
    margin-top: 60px;
  }
  .perks-benefits .box {
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    height: 215px;
    margin-top: 60px;
    width: calc(100% - 7.5px);
    margin-bottom: 20px;
  }
  @media (max-width: 991.5px) {
    .perks-benefits .box {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .perks-benefits .box::before {
    content: "";
    width: calc(100% + 20px);
    height: calc(100% - 10px);
    border: 1px solid #ABA7A0;
    border-radius: 20px;
    position: absolute;
    top: 20px;
    left: -10px;
    z-index: -1;
  }
  .perks-benefits .box h4 {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    max-width: 200px;
    margin: auto;
    line-height: 24px;
    color: #0E0E0F;
  }
  .perks-benefits .box .count {
    position: absolute;
    top: -55px;
    left: 0;
    right: 0;
    margin-left: calc(50% - 55px);
    width: 95px;
    z-index: 1;
  }
  .perks-benefits .box .count p {
    font-size: 48px;
    font-family: "Gilroy";
    font-weight: 600;
    color: #004861;
    position: relative;
    margin: 0;
    line-height: 7rem;
    padding-left: 25px;
  }
  .perks-benefits .box .count p::before {
    content: "";
    position: absolute;
    width: 65px;
    height: 113px;
    background: #AEEAFF;
    border-radius: 40px;
    z-index: -1;
    left: 0;
  }
  .perks-benefits .features {
    position: relative;
  }
  .perks-benefits .features::before {
    content: "";
    position: absolute;
    bottom: -60px;
    right: calc(50vw + 320px);
 
    width: 346px;
    height: 312px;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .perks-benefits .features::after {
    content: "";
    position: absolute;
    top: -40px;
    right: -80px;
 
    background-size: 100%;
    background-repeat: no-repeat;
    width: 246px;
    height: 246px;
    z-index: -1;
  }
  @media (max-width: 1500px) {
    .perks-benefits .features::after {
      width: 200px;
      height: 200px;
      right: -30px;
    }
  }
  @media (max-width: 1250px) {
    .perks-benefits .features::after {
      display: none;
    }
  }
  .perks-benefits .features .feature-box {
    background: #EFFAFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    min-height: 268px;
    margin-bottom: 25px;
    -webkit-transition: all 0.2 ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .perks-benefits .features .feature-box::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 0;
    border-color: white transparent transparent transparent;
    left: 0;
    top: 0;
    position: absolute;
  }
  .perks-benefits .features .feature-box .f-icon {
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 18px;
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .perks-benefits .features .feature-box .f-icon img {
    max-width: 54px;
    height: 54px;
    transition: transform 0.2s;
  }
  .perks-benefits .features .feature-box:hover {
    margin-top: -10px;
  }
  .perks-benefits .features .feature-box:hover .f-icon img {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
  }
  .perks-benefits .features .feature-box h4 {
    color: #011E41;
    font-family: #FF651A;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .perks-benefits .features .feature-box p {
    color: #525252;
    font-size: 15px;
  }
  

  .slick-prev, .slick-next {
    display: none !important;
}
